import React, { useEffect, useState } from 'react'
import { Newsletter, RelatedPosts, Link, Footer, Loading } from '~/components'
import * as st from '~/assets/styl/PostPage.module.styl'
import arrowBack from '~/assets/svg/icon-yellow-left.svg'
import iconWhatsapp from '~/assets/svg/icon-zap.svg'
import iconFacebook from '~/assets/svg/facebook.svg'
import iconLinkedin from '~/assets/svg/icon-linkdin.svg'
import { useApiBlog as api } from '~/siteApi'
import { formatDate } from '~/utils/formatDate'
import { format, formatDistanceToNow } from 'date-fns'
import { ptBR } from 'date-fns/locale'

const PostPage = () => {
  const getSlugFromUrl = () => {
    if (typeof window !== 'undefined') {
      const path = window.location.pathname
      const slug = path.split('/').filter(Boolean).pop()
      return slug
    }
    return null
  }

  const slug = getSlugFromUrl()
  const [post, setPost] = useState<Post[]>([])
  const [posts, setPosts] = useState([])
  const [categories, setCategories] = useState([])
  const [loadingPost, setLoadingPost] = useState(true)
  const [media, setMedia] = useState('')

  const getCurrentUrl = () => {
    return typeof window !== 'undefined' ? window.location.href : ''
  }

  const links: Array<{ img: string; src: string }> = [
    {
      img: iconWhatsapp,
      src: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        getCurrentUrl()
      )}`,
    },
    {
      img: iconFacebook,
      src: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        getCurrentUrl()
      )}`,
    },
    {
      img: iconLinkedin,
      src: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        getCurrentUrl()
      )}`,
    },
  ]

  const getPost = async () => {
    try {
      const request = await api.requestData('get', `posts?_embed&slug=${slug}`)
      setPost(request.data)
    } catch (error) {
      console.error('Erro ao carregar o post:', error)
    } finally {
      setLoadingPost(false)
    }
  }

  const getAllCategories = async () => {
    try {
      const request = await api.requestData('get', 'categories?per_page=99')
      setCategories(request.data)
    } catch (error) {
      console.error('Erro ao carregar categorias:', error)
    }
  }

  const getAllPosts = async () => {
    if (post.length > 0) {
      try {
        const request = await api.requestData(
          'get',
          `posts?_embed&categories=${post[0]?.categories}&exclude=${post[0]?.id}`
        )
        setPosts(request.data)
      } catch (error) {
        console.error('Erro ao carregar posts relacionados:', error)
      }
    }
  }

  const getCategoryNameById = (categoryId) => {
    const category = categories.find((cat) => cat.id === categoryId)
    return category ? category.name : 'Categoria desconhecida'
  }

  const getMedia = async () => {
    if (post.length > 0 && post[0]?.featured_media) {
      try {
        const request = await api.requestData(
          'get',
          `media/${post[0].featured_media}`
        )
        setMedia(
          request.data.media_details.sizes['single-thumbnail']?.source_url
        )
      } catch (error) {
        console.error('Erro ao carregar a mídia:', error)
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([getPost(), getAllCategories()])
      } catch (error) {
        console.error('Erro ao carregar dados iniciais:', error)
      }
    }

    fetchData()
  }, [slug])

  useEffect(() => {
    if (post.length > 0) {
      getAllPosts()
      getMedia()
    }
  }, [])

  const currentPost = post.length > 0 ? post[0] : null
  const author = currentPost ? currentPost?._embedded?.['author']?.[0] : null
  const formattedDate = currentPost
    ? format(
        new Date(currentPost?.date),
        // eslint-disable-next-line quotes
        "dd/MM/yyyy 'às' HH:mm",
        {
          locale: ptBR,
        }
      )
    : null
  const updatedTime = currentPost
    ? formatDistanceToNow(new Date(currentPost?.modified), {
        locale: ptBR,
        addSuffix: false,
      })
    : null

  if (loadingPost) {
    return <Loading visible />
  }

  return (
    <>
      {(currentPost && (
        <div className={st.core}>
          <div className={st.header}>
            <div className={st.back}>
              <Link href="/blog">
                <img src={arrowBack} alt="Voltar" />
                Voltar
              </Link>
            </div>
            {currentPost && currentPost.categories && (
              <div className={st.tags}>
                {currentPost.categories.map((categoryId, index) => (
                  <span key={index}>{getCategoryNameById(categoryId)}</span>
                ))}
              </div>
            )}
            {currentPost && (
              <>
                <h1>{currentPost.title?.rendered}</h1>
                <span className={st.date}>{formatDate(currentPost.date)}</span>
              </>
            )}
          </div>
          {media && (
            <div className={st.imgEmphasis}>
              <img
                src={media}
                width={1920}
                height={708}
                alt="Imagem de destaque"
              />
            </div>
          )}
          <div className={st.grid}>
            <div className={st.subGrid}>
              {currentPost && (
                <>
                  <section>
                    <div className={st.imageText}>
                      <img
                        src={author.avatar_urls['48']}
                        alt={author.name}
                        width={58}
                        height={58}
                      />
                      <div className={st.author}>
                        <p>
                          <a href={`/blog/autor/${author.slug}`}>
                            {author.name}
                          </a>
                          <span>{author.job_title}</span>
                        </p>
                      </div>
                    </div>
                    <div className={st.date}>
                      <p>
                        {formattedDate} • Atualizado há {updatedTime}
                      </p>
                    </div>
                  </section>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentPost.content?.rendered || '',
                    }}
                  />
                </>
              )}
              {posts.length > 0 && (
                <RelatedPosts
                  posts={posts}
                  categoriesName={getCategoryNameById}
                />
              )}
              <section className={st.browseCategories}>
                <h2>Navegue em outras categorias</h2>
                {categories.length > 0 && (
                  <div className={st.tags}>
                    {categories
                      .filter((c) => c.count > 0)
                      .map((category, index) => (
                        <Link
                          href={`/blog/categoria/${category.name}`}
                          key={index}
                        >
                          {category.name}
                        </Link>
                      ))}
                  </div>
                )}
              </section>
              <section className={st.share}>
                <h2>Gostou? Compartilhe </h2>
                <div className={st.social}>
                  {links.map(({ img, src }, index) => (
                    <Link external href={src} key={index}>
                      <img src={img} alt="Imagem de compartilhamento" />
                    </Link>
                  ))}
                </div>
              </section>
            </div>
            <Newsletter categorieOrSearch />
          </div>
        </div>
      )) || <Loading visible />}
      <Footer />
    </>
  )
}

export default PostPage
