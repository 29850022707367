// extracted by mini-css-extract-plugin
export var author = "PostPage-module--author--aea2a";
export var back = "PostPage-module--back--60df8";
export var browseCategories = "PostPage-module--browseCategories--407bc";
export var core = "PostPage-module--core--6f2be";
export var date = "PostPage-module--date--5e0d9";
export var grid = "PostPage-module--grid--4789d";
export var header = "PostPage-module--header--0758e";
export var imageText = "PostPage-module--imageText--4b4fc";
export var imgEmphasis = "PostPage-module--imgEmphasis--46f8d";
export var imgs = "PostPage-module--imgs--10108";
export var share = "PostPage-module--share--e8d88";
export var social = "PostPage-module--social--6b5ab";
export var subGrid = "PostPage-module--subGrid--27d90";
export var tags = "PostPage-module--tags--46758";